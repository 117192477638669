<template>
  <div class="stemcells">
    <div class="cells-container">
     <Scintroductdiv :Scintroduct="Scintroduct" :title='""' :Subtitle="'香港新康复项目'"></Scintroductdiv>
     <Donatetoknow :donatetoknow="donatetoknow" :title="''" :Subtitle="'申请流程'"></Donatetoknow>
     <TitleMore
      :title="['工作动态']"
      :isMore="false"
        ></TitleMore>
    <div class="baseCard">
      <div
        class="itemText"
		style="margin-bottom: 10px;"
        v-for="item in ListData"
        :key="item.id"
        @click="$goto('/detailShow',{id:item.id})"
      >
        <p>
          <img
            src="../../../assets/img/list-little.png"
            alt=""
          >
          <span class="itemTextContent">{{item.title}}</span>
        </p>
        <p class="itemTextTime">{{item.publishDate}}</p>
      </div>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    </div>
  </div>
</template>

<script>
// import Worktrends from './components/worktrends'
import TitleMore from "@components/TitleMore";
import Scintroductdiv from '../../Bodydonate/components/scintroduct'
import Donatetoknow from './components/donatetoknow'
import apiUrls from '@api'
export default {
  components : {
      Scintroductdiv,
      Donatetoknow,
      TitleMore
  },
  name: 'Stemcells',
  data () {
    return {
      workinfolist: [],
      Statisticslist: {},
      drystyle: [],
      drystyletitle: "造干志愿者风采",
      popularlist: [],
      ListData:[],
      Scintroduct : {},
      donatetoknow: {},
      page:0,
      size:10,
      total:0
    }
  },
  created () {
    this.getdonatetoknow()
    this.getScintroduct()
    this.getList()
  },
  methods : {
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    getScintroduct() {
      apiUrls.getStatistics({node: 'xgxkfxm_xmjj'}).then(res => {

        this.Scintroduct = res.results.data
      })
    },
    getdonatetoknow() {
      apiUrls.getangelfund({node: 'xgxkfxm_sqlc'}).then(res => {

        this.donatetoknow = res.results.data
      })
    },
    getList() {
      apiUrls.gethelpdate({node: 'xgxkfxm_xwdt',page:this.page,size:this.size}).then(res=>{
        this.ListData= res.results.data;
        this.total = res.results.pager.totalElements;
      })
    },
  }
}
</script>

<style lang="less" scoped>
.stemcells {
  background-color: #f5f5f5;
  .cells-container {
    width: 1200px;
    min-height: 400px;
    padding-bottom: 40px;
    margin: 0 auto;
    background-color: #f5f5f5;

    &::before {
        display: block;
        content: ".";
        clear: both;
        height: 0;
        overflow: hidden;
        visibility: hidden; 
    }
  }
}
</style>
